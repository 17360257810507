import { Pipe, PipeTransform } from '@angular/core';
import { Subfleet } from '@hub-services/subfleets.service';

@Pipe({
  name: 'getSubfleetName'
})
export class GetSubfleetNamePipe implements PipeTransform {

  transform(subfleetId: number, subfleets: Subfleet[]): string {

    const subfleet = subfleets.find(subfleet => subfleet.id === subfleetId);
    return subfleet ? subfleet.name : '';
  }

}
