import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxEditorModule } from 'ngx-editor';
import { AirportTypeaheadComponent } from './airport-typeahead/airport-typeahead.component';
import { AppMessagesComponent } from './app-messages.component';
import { CategoriesComponent } from './category-selector/categories.component';
import { CategoriesFilter } from './category-selector/categories.filter';
import { ContentBlockerComponent } from './content-blocker/content-blocker.component';
import { ContextNavComponent } from './context-nav/context-nav.component';
import { PartnerSwitcherComponent } from './context-nav/partner-switcher.component';
import { SavedViewsListComponent } from './context-nav/saved-views/saved-views-list.component';
import { SavedViewsComponent } from './context-nav/saved-views/saved-views.component';
import { CountryTypeaheadComponent } from './country-typeahead.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { DragToSelectDirective } from './drag-to-select.directive';
import { FlexMainHeightDirective } from './flex-main-height.directive';
import { FlightSearchComponent } from './flight-search/flight-search.component';
import { FocusDirective } from './focus.directive';
import { FooterComponent } from './footer.component';
import { GenericTypeaheadComponent } from './generic-typeahead/generic-typeahead.component';
import { RhContextUtilityNavComponent } from './header/context-utility-nav.component';
import { HeaderLogoSwitcherComponent } from './header/header-logo-switcher.component';
import { RhHeaderNavComponent } from './header/header-nav.component';
import { HubMenuComponent } from './header/hub-menu.component';
import { LanguagePickerComponent } from './language/language-picker.component';
import { LanguagePickerV2Component } from './language-v2/language-picker-v2.component';
import { ListFormComponent } from './list-form/list-form.component';
import { MagicHeaderInsetDirective } from './magic-page-header-inset.directive';
import { MagicHeaderDirective } from './magic-page-header.directive';
import { MagicSubHeaderDirective } from './magic-sub-page-header.directive';
import { TargetingByMarketComponent } from './markets/targeting-by-market.component';
import { NoContentComponent } from './no-content.component';
import { RhPageLoaderComponent } from './page-loading.component';
import { PreviewModalComponent } from './preview-modal/preview-modal.component';
import { ProfileComponent } from './profile/profile.component';
import { RhLoaderComponent } from './rh-loader.component';
import { RhLogoComponent } from './rh-logo.component';
import { ScrollAnchorDirective } from './scroll/scroll-anchor.directive';
import { ScrollContainerDirective } from './scroll/scroll-container.directive';
import { ScrollRefDirective } from './scroll/scroll-ref.directive';
import { RhSectionTabsComponent } from './section-tabs/section-tabs.component';
import { SelectedIdsOrTermsCategoryFilter } from './selected-ids-terms-category.filter';
import { SelectedIdsOrTermsUasFilter } from './selected-ids-terms-uas.filter';
import { SubfleetTypeaheadComponent } from './subfleet-typeahead.component';
import { TerminalTypeaheadComponent } from './terminal-typeahead.component';
import { UasFilter } from './uas-filter.filter';
import { SimpleFilter } from './simple-filter.filter';
import { MultiCheckDropdownComponent } from './multicheck-dropdown.component';
import { RhButtonClearComponent } from './buttons/rh-button-clear.component';
import { RhButtonDeleteComponent } from './buttons/rh-button-delete.component';
import { PartnershipsFilter } from './filters/partnerships-filter.filter';
import { PartnersFilter } from './filters/partners-filter.filter';
import { RhCabinBrandFilterComponent } from './ua-filters/cabin-brand/rh-cabin-brand-filter.component';
import { RhCategoryFilterComponent } from './ua-filters/category/rh-category-filter.component';
import { RhChannelFilterComponent } from './ua-filters/channel/rh-channel-filter.component';
import { RhFilterTypeaheadTypeaheadComponent } from './ua-filters/rh-filter-typeahead.component';
import { RhSubfleetFilterComponent } from './ua-filters/subfleet/rh-subfleet-filter.component';
import { RhCheckboxComponent } from './form-components/rh-checkbox.component';
import { HubUiModule } from '@atpco/hub-ui';
import { GetDisplayCodePipe } from './language/get-display-code.pipe';
import { ModalDisplayValuePipe } from './context-nav/modal-display-value.pipe';
import { IsFilteringPipe } from './filters/is-filtering.pipe';
import { SharedPipesModule } from '../shared/shared-pipes/shared-pipes.module';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { RhUpaSourceComponent } from './ua-filters/source/rh-upa-source.component';
import { GetLinkToMarketPipe } from './markets/get-link-to-market.pipe';
import { CannotSubmitListFormPipe } from './list-form/cannot-submit-list-form.pipe';
import { GetCaptionPipe } from './preview-modal/get-caption.pipe';
import { ListFilterPlaceholderPipe } from './list-filter-placeholder.pipe';
import { CurrencyNamePipe } from './currency-name.pipe';
import { ObserversSidebarComponent } from '../observers/shared/observers-sidebar/observers-sidebar.component';
import { ObserversBreadcrumbsComponent } from '../observers/shared/observers-breadcrumbs/observers-breadcrumbs.component';
import { ExpandableAutocompleteComponent } from './expandable-autocomplete/expandable-autocomplete.component';
import { ExpandableListComponent } from './expandable-list/expandable-list.component';
import { FieldTooltipComponent } from './field-tooltip/field-tooltip.component';
import { FormattedTextEditorComponent } from './text-editor/formatted-text-editor.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RhHelpSlideoverComponent } from './help-slideover/help-slideover.component';
import { SearchBarSetFilter } from './filters/search-bar-set-filter/search-bar-set-filter.component';
import { UpaFilter } from './filters/upa-filter.pipe';
import { StandardSearchBarComponent } from './standard-search-bar/standard-search-bar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    HubUiModule,
    SharedPipesModule,
    NgxEditorModule,
    DragDropModule
  ],
  exports: [
    AppMessagesComponent,
    DeleteModalComponent,
    ContextNavComponent,
    SimpleFilter,
    SelectedIdsOrTermsCategoryFilter,
    SelectedIdsOrTermsUasFilter,
    RhLoaderComponent,
    LanguagePickerComponent,
    LanguagePickerV2Component,
    MagicHeaderDirective,
    MagicHeaderInsetDirective,
    HubMenuComponent,
    HeaderLogoSwitcherComponent,
    PartnerSwitcherComponent,
    FooterComponent,
    FlightSearchComponent,
    AirportTypeaheadComponent,
    NoContentComponent,
    FocusDirective,
    UasFilter,
    FlexMainHeightDirective,
    MagicSubHeaderDirective,
    MultiCheckDropdownComponent,
    ScrollContainerDirective,
    GenericTypeaheadComponent,
    SubfleetTypeaheadComponent,
    TerminalTypeaheadComponent,
    CountryTypeaheadComponent,
    RhPageLoaderComponent,
    PreviewModalComponent,
    ScrollContainerDirective,
    ScrollRefDirective,
    ScrollAnchorDirective,
    CategoriesComponent,
    CategoriesFilter,
    RhLogoComponent,
    ListFormComponent,
    DragToSelectDirective,
    TargetingByMarketComponent,
    PartnershipsFilter,
    PartnersFilter,
    UpaFilter,
    SavedViewsComponent,
    SavedViewsListComponent,
    RhHeaderNavComponent,
    RhHelpSlideoverComponent,
    RhContextUtilityNavComponent,
    RhSectionTabsComponent,
    RhCheckboxComponent,
    RhCategoryFilterComponent,
    RhFilterTypeaheadTypeaheadComponent,
    RhChannelFilterComponent,
    RhCabinBrandFilterComponent,
    RhSubfleetFilterComponent,
    RhButtonClearComponent,
    RhButtonDeleteComponent,
    ContentBlockerComponent,
    ProfileComponent,
    IsFilteringPipe,
    BreadcrumbsComponent,
    RhUpaSourceComponent,
    ListFilterPlaceholderPipe,
    GetCaptionPipe,
    CurrencyNamePipe,
    ObserversSidebarComponent,
    ObserversBreadcrumbsComponent,
    ExpandableAutocompleteComponent,
    FieldTooltipComponent,
    FormattedTextEditorComponent,
    SearchBarSetFilter,
    StandardSearchBarComponent,
  ],
  declarations: [
    AppMessagesComponent,
    DeleteModalComponent,
    ContextNavComponent,
    SimpleFilter,
    SelectedIdsOrTermsCategoryFilter,
    SelectedIdsOrTermsUasFilter,
    RhLoaderComponent,
    LanguagePickerComponent,
    LanguagePickerV2Component,
    MagicHeaderDirective,
    MagicHeaderInsetDirective,
    HubMenuComponent,
    HeaderLogoSwitcherComponent,
    PartnerSwitcherComponent,
    FooterComponent,
    FlightSearchComponent,
    AirportTypeaheadComponent,
    NoContentComponent,
    FocusDirective,
    UasFilter,
    MultiCheckDropdownComponent,
    MagicSubHeaderDirective,
    FlexMainHeightDirective,
    GenericTypeaheadComponent,
    SubfleetTypeaheadComponent,
    TerminalTypeaheadComponent,
    CountryTypeaheadComponent,
    RhPageLoaderComponent,
    PreviewModalComponent,
    ScrollContainerDirective,
    ScrollRefDirective,
    ScrollAnchorDirective,
    CategoriesComponent,
    CategoriesFilter,
    RhLogoComponent,
    ListFormComponent,
    DragToSelectDirective,
    TargetingByMarketComponent,
    PartnershipsFilter,
    PartnersFilter,
    UpaFilter,
    SavedViewsComponent,
    SavedViewsListComponent,
    RhHeaderNavComponent,
    RhHelpSlideoverComponent,
    RhContextUtilityNavComponent,
    RhSectionTabsComponent,
    RhCheckboxComponent,
    RhCategoryFilterComponent,
    RhFilterTypeaheadTypeaheadComponent,
    RhChannelFilterComponent,
    RhCabinBrandFilterComponent,
    RhSubfleetFilterComponent,
    RhButtonClearComponent,
    RhButtonDeleteComponent,
    ContentBlockerComponent,
    ProfileComponent,
    GetDisplayCodePipe,
    ModalDisplayValuePipe,
    IsFilteringPipe,
    BreadcrumbsComponent,
    RhUpaSourceComponent,
    GetLinkToMarketPipe,
    ListFilterPlaceholderPipe,
    CannotSubmitListFormPipe,
    GetCaptionPipe,
    CurrencyNamePipe,
    ObserversSidebarComponent,
    ObserversBreadcrumbsComponent,
    ExpandableAutocompleteComponent,
    ExpandableListComponent,
    FieldTooltipComponent,
    FormattedTextEditorComponent,
    SearchBarSetFilter,
    StandardSearchBarComponent,
  ],
})
export class RhCommonModule {
  static forRoot(): ModuleWithProviders<RhCommonModule> {
    return {
      ngModule: RhCommonModule,
    };
  }
}
