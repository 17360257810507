import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getCategoryNames'
})
export class GetCategoryNamesPipe implements PipeTransform {

  transform(categoryIds: string[], categories): any[] {
    return categories ? categoryIds.map((id) => categories.find(category => category.id === id)?.name ?? 'No category') : categoryIds;
  }
}
