import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getAirportName'
})
export class GetAirportNamePipe implements PipeTransform {

  transform(airportCode: string, airports: any[]): string {
    const airport = airports.find(airport => airport.code === airportCode);
    return airport ? airport.name : '';
  }

}
