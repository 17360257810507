import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isCabinTargeted'
})
export class IsCabinTargetedPipe implements PipeTransform {

  transform(cabin_targeting: {cabin_id: number, brand_ids: number[]}[]): boolean {
    return !cabin_targeting.some(cabinTarget => cabinTarget.brand_ids.length > 0)
  }

}