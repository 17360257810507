import { Pipe, PipeTransform } from '@angular/core';
import { MediaType } from '../../uas/editing/media/media-type.enum';
import { DateTime } from 'luxon';

@Pipe({
  name: 'formatMediaDate'
})
export class FormatMediaDatePipe implements PipeTransform {

  transform(date: string, mediaType: MediaType): string {
    if (date) {
      if (mediaType === MediaType.IMAGE_360) {
        return DateTime.fromFormat(date, 'yyyy-LL-dd').toFormat('LLL dd, yyyy');
      } else if (mediaType === MediaType.VIDEO) {
        return DateTime.fromFormat(date, 'dd-LL-yyyy').toFormat('LLL dd, yyyy');
      } else {
        return DateTime.fromFormat(date, 'yyLLdd').toFormat('LLL dd, yyyy');
      }
    } else { 
      return '';
    }
  }

}
