import { Pipe, PipeTransform } from '@angular/core';
import { Cabin } from '@hub-services/interfaces/cabin';

@Pipe({
  name: 'getCabinName'
})
export class GetCabinNamePipe implements PipeTransform {

  transform(cabinId: number, cabins: any[]): string {
    const cabin = cabins.find(cabin => cabin.id === cabinId);
    return cabin ? cabin.name : '';
  }

}
